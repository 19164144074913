import { CellClassParams, ColDef, ColumnFunctionCallbackParams, GridOptions } from '@ag-grid-community/core';
import { Injectable } from '@angular/core';
import { camelCase, isNil } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from 'src/app/_core/authorization/auth.service';
import { SettingsService } from 'src/app/_core/settings/settings.service';
import { TranslationsService } from 'src/app/_core/translations.service';
import { AgGridOptionsService } from 'src/app/_shared/ag-grid/ag-grid-options.service';
import { ItemDataPoint } from 'src/app/item-card/grid/models/item-data-point.model';
import { ItemCardService } from 'src/app/item-card/item-card.service';
import { ChartDataParams } from 'src/app/item-card/models/chart-data-params.model';
import { ChartDataDTO, ChartElementTypeDTO, ItemCardDTO, SerieDataUpdateResponseDTO } from 'src/app/item-card/models/item-card-dto.model';
import { StoreService } from 'src/app/_core/store.service';

export interface ItemCardGridData {
  itemDataPoints: ItemDataPoint[];
  colDefs: ColDef[];
  chartElementTypes: ChartElementTypeDTO[];
}

@Injectable({
  providedIn: 'root'
})
export class ItemCardGridService {
  colDefs: ColDef[] = [
    { field: 'date', headerName: 'DATE', hide: false, type: 'date' },
    { field: 'sale', headerName: 'SALE', hide: false, type: 'number', editable: this.isEditable.bind(this) },
    { field: 'saleComment', headerName: 'SALE_COMMENT', hide: false, type: 'string', editable: this.isEditable.bind(this) },
    { field: 'originalSale', headerName: 'ORIGINAL_SALE', hide: false, type: 'number' },
    { field: 'stockHistory', headerName: 'STOCK_HISTORY', hide: true, type: 'number' },
    { field: 'forecast', headerName: 'FORECAST', hide: false, type: 'number' },
    { field: 'reserved', headerName: 'RESERVED', hide: true, type: 'number' },
    { field: 'knownDemand', headerName: 'KNOWN_DEMAND', hide: true, type: 'number' },
    { field: 'estimatedStock', headerName: 'ESTIMATED_STOCK', hide: true, type: 'number' },
    { field: 'safetyStockForOrderPeriod', headerName: 'SAFETY_STOCK_FOR_ORDER_PERIOD', hide: true, type: 'number' },
    { field: 'undelivered', headerName: 'UNDELIVERED', hide: true, type: 'number' },
    { field: 'orderQty', headerName: 'ORDER_QTY', hide: true, type: 'number' },
    { field: 'purchasePlanOrderQty', headerName: 'PURCHASE_PLAN_ORDER_QTY', hide: true, type: 'number' },
    { field: 'bomDemand', headerName: 'BOM_DEMAND', hide: true, type: 'number' },
    { field: 'minStockLevel', headerName: 'MINIMUM_STOCK_LEVEL', hide: false, type: 'number' },
    { field: 'plannerBaseline', headerName: 'BASELINE_PLAN', hide: true, type: 'number' },
    { field: 'plannerPromo', headerName: 'PLANNER_PROMO', hide: true, type: 'number' },
    { field: 'stockout', headerName: 'STOCKOUT_COMMITTED', hide: true, type: 'number' },
    { field: 'purchasePlanCalculated', headerName: 'PURCHASE_PLAN_CALCULATED', hide: true, type: 'number' },
    { field: 'demandFromStores', headerName: 'DEMAND_FROM_STORES', hide: true, type: 'number' },
    { field: 'plannedOrderQty', headerName: 'PLANNED_ORDER_QTY', hide: true, type: 'number' }
  ];

  constructor(
    private authService: AuthService,
    private agGridOptionsService: AgGridOptionsService,
    private itemCardService: ItemCardService,
    private settingsService: SettingsService,
    private storeService: StoreService
  ) {}

  getGridDataByPeriod(itemId: number, chartParams: ChartDataParams, orderId?: number): Observable<ItemCardGridData> {
    const dayOfWeek = this.settingsService.startOfWeek();
    return this.getGridData(itemId, chartParams, orderId).pipe(
      map((itemCardGridData: ItemCardGridData) => {
        this.addStockValues(itemCardGridData.itemDataPoints);
        this.aggregateSummedSeriesForPeriod(itemCardGridData.itemDataPoints, chartParams, itemCardGridData.chartElementTypes);
        if (chartParams.periodType === 'week') {
          itemCardGridData.itemDataPoints = weekFilter(itemCardGridData.itemDataPoints, dayOfWeek);
        }
        if (chartParams.periodType === 'month') {
          itemCardGridData.itemDataPoints = monthFilter(itemCardGridData.itemDataPoints);
        }
        return itemCardGridData;
      })
    );

    function weekFilter(itemDataPoints: ItemDataPoint[], weekday: number = 1): ItemDataPoint[] {
      return itemDataPoints.filter((itemDataPoint) => itemDataPoint.date.getDay() === weekday || itemDataPoint.isAggregatedOverPeriod);
    }

    function monthFilter(itemDataPoints: ItemDataPoint[]): ItemDataPoint[] {
      return itemDataPoints.filter((itemDataPoint) => itemDataPoint.date.getDate() === 1 || itemDataPoint.isAggregatedOverPeriod);
    }
  }

  updateItemDataPoint(itemId: number, params: ChartDataParams, itemData: ItemDataPoint): Observable<SerieDataUpdateResponseDTO> {
    return this.itemCardService.updateSerieData(itemId, params, 1, itemData.toSerieDataUpdateDto());
  }

  getGridOptions(): GridOptions {
    const gridOptions = this.agGridOptionsService.getDataOptions();
    gridOptions.defaultColDef.enableRowGroup = false;
    gridOptions.rowGroupPanelShow = 'never';
    gridOptions.defaultColDef.cellClassRules.edited = (params) => {
      if (!params.node || !params.node.data || params.node.group) {
        return false;
      }
      return this.isEdited(params);
    };
    gridOptions.defaultColDef.cellClassRules.today = (params) => {
      if (!params.node || !params.node.data || params.node.group) {
        return false;
      }
      return (params.node.data as ItemDataPoint).isToday;
    };
    return gridOptions;
  }

  private getGridData(itemId: number, params: ChartDataParams, orderId?: number): Observable<ItemCardGridData> {
    // @ts-ignore, getItemCardDto is private for a reason
    return this.itemCardService.getItemCardDto(itemId, params, orderId).pipe(
      map((itemDTO: ItemCardDTO) => {
        const colDefs = this.getColumnDefs(itemDTO.chartElementTypes);
        const itemsMap = new Map<number, ItemDataPoint>();
        itemDTO.chartData.map((chartData: ChartDataDTO) => {
          if (itemsMap.has(chartData.chart_date)) {
            itemsMap.get(chartData.chart_date).update(chartData, itemDTO.chartElementTypes);
          } else {
            itemsMap.set(chartData.chart_date, new ItemDataPoint(chartData, itemDTO.chartElementTypes));
          }
        });
        return {
          colDefs,
          itemDataPoints: Array.from(itemsMap.values()),
          chartElementTypes: itemDTO.chartElementTypes
        };
      })
    );
  }

  private getColumnDefs(chartElementTypes: ChartElementTypeDTO[]): ColDef[] {
    let colDef;
    chartElementTypes.map((elementType) => {
      colDef = this.colDefs.find((colDef) => colDef.field === camelCase(elementType.name));
      if (colDef) {
        colDef.headerName = elementType.chartLabel;
        return;
      }
      this.colDefs.push({
        field: camelCase(elementType.name),
        headerName: elementType.chartLabel,
        headerTooltip: elementType.description || elementType.chartLabel,
        type: 'number'
      });
    });
    this.colDefs.map((column: ColDef) => {
      if (column.headerName) {
        column.headerName = TranslationsService.get(column.headerName);
        column.headerTooltip = column.headerName;
      }
    });
    this.reorderAndHideColumns();
    return this.colDefs;
  }

  // reorders and hides as columns are in local storage
  private reorderAndHideColumns(): void {
    const columns = this.storeService.get('item-data.grid.columnState') as ColDef[];
    if (!columns) {
      return;
    }
    let currIndex;
    columns.forEach((column, index) => {
      currIndex = this.colDefs.findIndex((col) => col.field === column.colId);
      this.colDefs[currIndex].hide = column.hide;
      this.colDefs.splice(index, 0, this.colDefs.splice(currIndex, 1)[0]);
    });

    return;
  }

  private isEdited(params: CellClassParams): boolean {
    const data = params.node.data as ItemDataPoint;
    switch (params.colDef.field) {
      case 'sale':
        const hasOriginalSale = data.sale === 0 && data.originalSale === undefined;
        const adjustedSale = data.sale !== data.originalSale;
        return !hasOriginalSale && adjustedSale;
      case 'saleComment':
        return !!data.saleComment;
      default:
        return false;
    }
  }

  private isEditable(params: ColumnFunctionCallbackParams): boolean {
    return params.data.date.valueOf() < Date.now() && this.authService.hasFeature('itemCard.mod');
  }

  /**
   * Adds stock values in the past for all items so they are visible in the grid even though the date doesn't contain any sales.
   */
  private addStockValues(items: ItemDataPoint[]): void {
    let lastStock: number;
    items.forEach((item) => {
      if (item.date < new Date()) {
        item.stockHistory = lastStock = isNaN(item.stockHistory) ? lastStock : item.stockHistory;
      }
    });
  }

  /**
   * Aggregate series on weekly and monthly level in the grid for the period where the aggregation function is 'sum' and the series
   * isn't already aggregated in the database function (i.e. aggregate_over_period === false).
   * Values are not aggregated in the database function because we want to see the values on the day level in the chart,
   * in the grid however, we'd like to see these values aggregated, thus implementing the sum functionality here.
   */
  // eslint-disable-next-line max-lines-per-function
  private aggregateSummedSeriesForPeriod(items: ItemDataPoint[], chartParams: ChartDataParams, elementTypes: ChartElementTypeDTO[]): void {
    if (chartParams.periodType === 'day') {
      return;
    }
    const summedSeries = elementTypes
      .filter((type) => type.aggregationCalcFunc === 'sum' && !type.aggregatedOverPeriod && type.name !== 'stockout')
      .map((type) => camelCase(type.name));
    let periodSum = new Map<string, number>(summedSeries.map((serie) => [serie, undefined]));
    let periodItem: ItemDataPoint = items[0];
    items.forEach((item) => {
      if (isNextPeriod(item, periodItem, chartParams)) {
        periodSum.forEach((value, key) => {
          if (!isNil(value)) {
            periodItem[key] = value;
          }
        });
        periodSum = new Map<string, number>(summedSeries.map((serie) => [serie, undefined]));
        periodItem = item;
      }
      summedSeries.forEach((key) => {
        if (!isNil(item[key])) {
          const sum = isNil(periodSum.get(key)) ? item[key] : periodSum.get(key) + +item[key];
          periodSum.set(key, sum);
        }
      });
    });

    function isNextPeriod(currItem: ItemDataPoint, periodItem: ItemDataPoint, chartParams: ChartDataParams): boolean {
      if (currItem.date.valueOf() <= periodItem.date.valueOf()) {
        return false;
      }
      if (chartParams.periodType === 'week') {
        const sixDaysPlus = 1000 * 60 * 60 * 24 * 6.5;
        return currItem.date.getDay() === periodItem.date.getDay() || currItem.date.valueOf() > periodItem.date.valueOf() + sixDaysPlus;
      }
      const isFirstOfMonth = currItem.date.getDate() === 1;
      const isMonthAhead =
        currItem.date.getMonth() > periodItem.date.getMonth() || currItem.date.getFullYear() > periodItem.date.getFullYear();
      return isFirstOfMonth || isMonthAhead;
    }
  }
}
